<!-- 选择头像框 -->
<template>
  <div class="select-main" @touchmove.prevent>
    <div class="pc_chooseitem">
      <div class="order-title order-title-1">请选择您的定制款式</div>
      <div class="item-content">
        <div v-for="(item, index) in pcObj" :key="index" @click="setSelectIndex(index)">
          <frameItem size="large" :frameData="item" :select="selectIndex==index+1"></frameItem>
        </div>
      </div>
      <div class="order-title order-title-2">请选择您的定制信息</div>
      <input
        type="text"
        class="infoInput"
        placeholder="点击填写内容"
        maxlength="3"
        v-model="frameInfo"
        @keyup="frameInfo=frameInfo.replace(/\s+/g,'')"
      />
      <div class="confirm-btn" @click="clickConfirmBtn">确定定制内容</div>
    </div>
    <pcConfirmDialog v-if="showPCConfirmDia" :selectData="this.selectData"></pcConfirmDialog>
  </div>
</template>

<script>
export default {
  props: ["pcObj"],
  data() {
    return {
      selectIndex: 0,
      frameInfo: "", //输入的定制信息
      showPCConfirmDia: false,
      selectData: null
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    // console.log("this.pcObj=>", this.pcObj);
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  components: {
    frameItem: () => import("./frameItem"),
    pcConfirmDialog: () => import("./pcConfirmDialog")
  },
  methods: {
    setSelectIndex: function(val) {
      this.selectIndex = Number(val) + 1;
    },
    clickConfirmBtn: function() {
      // console.log("点击了确定按钮");
      // console.log("selectIndex=>", this.selectIndex);
      // console.log("定制内容=>", this.frameInfo);
      if (this.selectIndex == 0) {
        alert("请选择定制款式");
        return;
      }
      //二次防空格
      this.frameInfo = this.frameInfo.replace(/\s+/g, "");
      if (!this.frameInfo) {
        alert("请输入定制内容");
        return;
      }
      this.selectData = {
        ...this.pcObj[this.selectIndex - 1],
        selectIndex: this.selectIndex,
        content: this.frameInfo
      };
      this.showPCConfirmDia = true;
    }
  }
};
</script>
<style scoped lang='scss'>
@import "../../style/main.scss";
.select-main {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.pc_chooseitem {
  width: 628px;
  height: 739px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include imgbg(imgUrl("pc_chooseitem.png"));
}
.order-title {
  width: 100%;
  position: absolute;
  text-align: center;
  color: white;
  font-size: 25px;
}
.order-title-1 {
  margin-top: 50px;
}
.order-title-2 {
  margin-top: 13px;
}
.item-content {
  width: 560px;
  height: 228px;
  margin-left: 34px;
  margin-top: 92px;
  //   background-color: turquoise;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
}
.infoInput {
  width: 419px;
  height: 63px;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  text-align: center;
  margin-left: 105px;
  margin-top: 54px;
  border-radius: 8px;
  color: white;
  font-size: 17px;
}
::-webkit-input-placeholder {
  color: #ffbc55;
  font-size: 17px;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #ffbc55;
  font-size: 17px;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffbc55;
  font-size: 17px;
}
:-ms-input-placeholder {
  color: #ffbc55;
  font-size: 17px;
}
.confirm-btn {
  width: 349px;
  height: 63px;
  background-color: #ffbc55;
  border-radius: 8px;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  color: #825414;
  font-size: 21px;
  margin-left: 140px;
  margin-top: 214px;
}
</style>